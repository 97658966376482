import React, { useCallback } from 'react';
import { Answer } from '../../../types/data-types';
import QuestionTemplateProps from '../questionTemplateProps';
import { Message, Title } from '../../styles/typography';
import { QuestionWrapper } from './style';

/*
the Template Component should focus on displaying the component properly
Its only logic responsibility is to say when the user try to change selection, the parent component (AskQuestion)
is responsible of the current Selection State
This component shouldt decide if the use can select or not this is AskQuestion responsibility
*/
const BasicQuestion = ({
  selectedAnswers,
  addSelectedAnswer,
  removeSelectedAnswer,
  question,
}: QuestionTemplateProps): JSX.Element => {
  const isSelected = useCallback(
    (answerId: string) =>
      !!selectedAnswers.find((sa) => sa.answerId === answerId),
    [selectedAnswers]
  );
  const onSelect = useCallback(
    (answer: Answer) =>
      isSelected(answer.answerId)
        ? removeSelectedAnswer(answer)
        : addSelectedAnswer(answer),
    [isSelected, addSelectedAnswer, removeSelectedAnswer]
  );
  return (
    <QuestionWrapper>
      <Title>{question?.friendlyName}</Title>
      <Message>Select {question?.numberOfPossibleAnswers}</Message>
      <div>
        {question?.answers.map((answer) => {
          const { answerId } = answer;
          return (
            <div key={answerId}>
              <input
                type="checkbox"
                value={answerId}
                checked={isSelected(answerId)}
                onChange={(): void => onSelect(answer)}
              />
              <span>{answerId}</span>
            </div>
          );
        })}
      </div>
    </QuestionWrapper>
  );
};

export default BasicQuestion;
