import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { Persona } from '../../types/data-types';
import { clearStateFromLocalStorage } from '../../utils/manageStateInLocalStorage';
import LanguageSwitcher from '../LanguageSwitcher';
import PersonaSumUp from '../PersonalSumUp';
import { DoubleResultsContainer, ResetButton, ResultsContainer } from './style';
import ResetQuiz from '../../assets/TakeTestAgain.jpg';
import ResetQuizFr from '../../assets/RecommencerTest.jpg';

interface TestResultProps {
  personaResults: Persona[];
  resetQuiz: () => void;
}
const TestResult = ({ personaResults, resetQuiz }: TestResultProps): JSX.Element => {
  useEffect(() => clearStateFromLocalStorage(), []);
  const [language] = useContext(LanguageContext);

  return (
    <ResultsContainer className="container">
      <LanguageSwitcher />
      {personaResults.length === 1 && (
        <>
          <div className="row p-md-3">
            <PersonaSumUp persona={personaResults[0]} />
          </div>
          <ResetButton type="button" onClick={resetQuiz}>
            <img src={language === 'en' ? ResetQuiz : ResetQuizFr} alt="Reset Quiz" />
          </ResetButton>
        </>
      )}
      {personaResults.length === 2 && (
        <>
          <DoubleResultsContainer className="row p-md-3">
            <PersonaSumUp persona={personaResults[0]} />
            <ResetButton type="button" onClick={resetQuiz}>
              <img src={language === 'en' ? ResetQuiz : ResetQuizFr} alt="Reset Quiz" />
            </ResetButton>
            <PersonaSumUp persona={personaResults[1]} />
          </DoubleResultsContainer>
        </>
      )}
    </ResultsContainer>
  );
};

export default TestResult;
