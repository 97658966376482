import { Result } from '../types/data-types';

// Types
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const PREV_QUESTION = 'PREV_QUESTION';
export const SET_STATE = 'SET_STATE';

export interface NextQuestionAction {
  type: typeof NEXT_QUESTION;
  payload: Result;
}

export interface PrevQuestionAction {
  type: typeof PREV_QUESTION;
}
export interface SetStateAction {
  type: typeof SET_STATE;
  payload: State;
}

export interface State {
  currentQuestion: number;
  resultsHistory: Result[];
}

export type ActionTypes = NextQuestionAction | PrevQuestionAction | SetStateAction;

// Action Creators
export function nextQuestion(result: Result): ActionTypes {
  return {
    type: NEXT_QUESTION,
    payload: result,
  };
}

export function prevQuestion(): ActionTypes {
  return {
    type: PREV_QUESTION,
  };
}

export function setState(state: State): ActionTypes {
  return {
    type: SET_STATE,
    payload: state,
  };
}

// Reducer
export const testReducer = (state: State, action: ActionTypes): State => {
  const { currentQuestion, resultsHistory } = state;
  switch (action.type) {
    case NEXT_QUESTION: {
      const { payload } = action;

      const nextState = {
        ...state,
        currentQuestion: currentQuestion + 1,
      };
      if (resultsHistory.length < currentQuestion) {
        return {
          ...nextState,
          resultsHistory: resultsHistory.concat([payload]),
        };
      }
      return {
        ...nextState,
        resultsHistory: resultsHistory.map((results, i) =>
          i === currentQuestion - 1 ? payload : results
        ),
      };
    }
    case PREV_QUESTION: {
      return {
        ...state,
        currentQuestion: currentQuestion > 1 ? currentQuestion - 1 : currentQuestion,
      };
    }
    case SET_STATE: {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};
