import React, { useCallback, useContext } from 'react';
import { LanguageContext } from '../../../LanguageContext';
import { Answer } from '../../../types/data-types';
import LocalizeImageLanguage from '../../../utils/LocalizeImageLanguage';
import QuestionTemplateProps from '../questionTemplateProps';
import { GridWrapper, GridCol, GridPhoto, GridButton } from './style';

const PhotoGrid = ({
  selectedAnswers,
  addSelectedAnswer,
  removeSelectedAnswer,
  question,
}: QuestionTemplateProps): JSX.Element => {
  const isSelected = useCallback(
    (answerId: string) => !!selectedAnswers.find(sa => sa.answerId === answerId),
    [selectedAnswers]
  );
  const onSelect = useCallback(
    (answer: Answer) =>
      isSelected(answer.answerId) ? removeSelectedAnswer(answer) : addSelectedAnswer(answer),
    [isSelected, addSelectedAnswer, removeSelectedAnswer]
  );
  const [language] = useContext(LanguageContext);
  return (
    <GridWrapper className="row m-0">
      {question?.answers.map(answer => {
        const { answerId, bgImg, fgImg } = answer;

        const photoGridImg = LocalizeImageLanguage(fgImg, language);

        return (
          <GridCol
            key={answerId}
            className={`col-4 ${isSelected(answerId) ? 'selected' : ''}`}
            bgImg={bgImg}
          >
            <GridButton onClick={(): void => onSelect(answer)}>
              <GridPhoto src={photoGridImg} className="photo" alt={answerId} />
            </GridButton>
          </GridCol>
        );
      })}
    </GridWrapper>
  );
};

export default PhotoGrid;
