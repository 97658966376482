import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { Persona } from '../../types/data-types';
import { AvailableLanguages } from '../../utils/languageManager';
import { EmphasisDescription, Intro, Subtitle } from '../styles/typography';
import { PersonaCollage, PersonaIcon, PersonaWrapper, Tag, TagList, ViewButton } from './style';

interface PersonaProps {
  persona: Persona;
}

const PersonaSumUp = ({ persona }: PersonaProps): JSX.Element => {
  const [language] = useContext(LanguageContext);
  return (
    <>
      {persona && (
        <PersonaWrapper className="col mb-3">
          <Intro>{language === 'en' ? 'You are' : 'Vous êtes'}</Intro>
          <PersonaIcon src={persona.icon} alt={persona.friendlyName[language]} />
          <Subtitle>{persona.friendlyName[language]}</Subtitle>

          <TagList>
            {persona.tags[language]?.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </TagList>

          <PersonaCollage src={persona.collage} alt={persona.friendlyName[language]} />
          <EmphasisDescription>
            {language === 'en'
              ? 'Curious about the complete result?'
              : 'Curieux de connaître le résultat complet ?'}
          </EmphasisDescription>
          <ViewButton
            href={buildUrlFromLanguage(persona.url, language)}
            target="_blank"
            rel="noopener"
          >
            {language === 'en' ? 'View' : 'Voir les résultats'}
          </ViewButton>
        </PersonaWrapper>
      )}
    </>
  );
};

const buildUrlFromLanguage = (url: string, language: AvailableLanguages): string => {
  if (language === AvailableLanguages.FR) {
    return url.replace(/https:\/\//, '$&fr.');
  }
  return url;
};

export default PersonaSumUp;
