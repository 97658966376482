import React, { useCallback, useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Answer } from '../../../types/data-types';
import QuestionTemplateProps from '../questionTemplateProps';
import { ImageGridWrapper, Item, Thumbnail, ItemButton, ThumbnailSlider } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LocalizeImageLanguage from '../../../utils/LocalizeImageLanguage';
import { LanguageContext } from '../../../LanguageContext';

const ImageCarousel = ({
  selectedAnswers,
  addSelectedAnswer,
  removeSelectedAnswer,
  question,
}: QuestionTemplateProps): JSX.Element => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  let slider1: never[] | Slider | null = [];
  let slider2: Slider | never[] | null = [];

  const [language] = useContext(LanguageContext);

  const mainSliderSettings = {
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
        },
      },
    ],
  };

  const thumbnailSliderSettings = {
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 7,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  const isSelected = useCallback(
    (answerId: string) => !!selectedAnswers.find(sa => sa.answerId === answerId),
    [selectedAnswers]
  );

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const onSelect = useCallback(
    (answer: Answer) =>
      isSelected(answer.answerId) ? removeSelectedAnswer(answer) : addSelectedAnswer(answer),
    [isSelected, addSelectedAnswer, removeSelectedAnswer]
  );
  return (
    <ImageGridWrapper>
      <Slider asNavFor={nav2} ref={slider => (slider1 = slider)} {...mainSliderSettings}>
        {question?.answers.map(answer => {
          const { answerId, fgImg } = answer;

          const GridImage = LocalizeImageLanguage(fgImg, language);

          return (
            <ItemButton type="button" key={answerId} onClick={(): void => onSelect(answer)}>
              <Item
                img={GridImage}
                className={isSelected(answerId) ? 'selected' : ''}
                tabIndex={0}
              />
            </ItemButton>
          );
        })}
      </Slider>
      <ThumbnailSlider
        asNavFor={nav1}
        ref={slider => (slider2 = slider)}
        {...thumbnailSliderSettings}
      >
        {question?.answers.map(answer => {
          const { answerId, fgImg } = answer;

          const CarouselImage = LocalizeImageLanguage(fgImg, language);

          return (
            <div key={answerId}>
              <Thumbnail img={CarouselImage} className={isSelected(answerId) ? 'selected' : ''} />
            </div>
          );
        })}
      </ThumbnailSlider>
    </ImageGridWrapper>
  );
};

export default ImageCarousel;
