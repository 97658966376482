import styled from 'styled-components';
import { EmphasisDescription, fwMedium } from '../styles/typography';
import { boxShadowFocus } from '../styles/ui';

export const PageWrapper = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 2em;
  // margin-top: 2em;

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
`;

export const Intro = styled.div`
  max-width: 500px;
  margin: 0 1em;

  @media only screen and (min-width: 768px) {
    margin: auto;
  }
`;

export const StartButton = styled.button`
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: 100px;
  height: 100px;

  @media only screen and (min-width: 768px) {
    width: 125px;
    height: 125px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  &:hover {
    transform: scale(1.175);
    transition: 0.25s ease-in-out;
  }

  &:focus {
    ${boxShadowFocus}
  }
`;

export const StyledEmphasisDescription = styled(EmphasisDescription)`
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;

export const MediumText = styled.span`
  ${fwMedium}
`;
