import styled, { css } from 'styled-components';
import { GridCol } from '../PhotoGrid/style';
import { boxShadowFocus } from '../../styles/ui';

interface PhotoBubbleProps {
  bgColor?: string;
}

const width = css`
  width: 90px;
  height: 90px;

  @media only screen and (min-width: 376px) {
    width: 120px;
    height: 120px;
  }

  @media only screen and (min-width: 600px) {
    width: 135px;
    height: 135px;
  }

  @media only screen and (min-width: 768px) {
    width: 175px;
    height: 175px;
  }

  @media only screen and (min-width: 1440px) {
    width: 200px;
    height: 200px;
  }
`;

export const BubbleGridWrapper = styled.div`
  height: 56vh;
  padding: 22px 30px;

  @media only screen and (min-width: 376px) {
    height: 60vh;
    padding: 10px 30px;
  }

  @media only screen and (min-width: 600px) {
    height: 70vh;
    padding: 10px 65px;
  }

  @media only screen and (min-width: 768px) {
    width: 90%;
    margin: auto;
    padding: 0 30px 30px;
  }

  @media only screen and (min-width: 992px) {
    padding: 0 50px 20px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 50px 30px;
  }
`;

export const PhotoGridItem = styled(GridCol)`
  background: none;

  &.selected .photo {
    transform: scale(1.175);
  }
  &.pr {
    padding-right: 15px;
  }
  &.pl {
    padding-left: 15px;
  }
  &.sm-top {
    top: -3.5em;
  }

  @media only screen and (min-width: 769px) {
    &.pr {
      padding-right: 8rem;
    }
    &.pl {
      padding-left: 8rem;
    }
    &.sm-top {
      top: unset;
    }
  }

  @media only screen and (min-width: 992px) {
    &.pr {
      padding-right: 10rem;
    }
    &.pl {
      padding-left: 10rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    &.pr {
      padding-right: 14rem;
    }
    &.pl {
      padding-left: 14rem;
    }
  }
`;

export const OptionButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
  ${width}

  &:focus {
    ${boxShadowFocus}
  }
`;

export const PhotoBubble = styled.img<PhotoBubbleProps>`
  display: block;
  background-color: ${(p): string | undefined => p.bgColor || '#ebebeb'};
  border-radius: 50%;
  ${width}

  &:hover {
    transform: scale(1.175);
    transition: 0.25s ease-in-out;
  }
`;
