import React, {useCallback, useContext} from 'react';
import {LanguageContext} from '../../LanguageContext';
import {AvailableLanguages} from '../../utils/languageManager';
import {ButtonWrapper, Button} from './style';

const LanguageSwitcher = (): JSX.Element => {
  const [language, setLanguage] = useContext(LanguageContext);
  const switchLanguage = useCallback(() => {
    if (language === AvailableLanguages.FR) {
      setLanguage(AvailableLanguages.EN);
    } else {
      setLanguage(AvailableLanguages.FR);
    }
  }, [language, setLanguage]);
  return (
    <ButtonWrapper>
      <Button type="button" onClick={switchLanguage}>
        {language === AvailableLanguages.FR ? AvailableLanguages.EN : AvailableLanguages.FR}
      </Button>
    </ButtonWrapper>
  );
};

export default LanguageSwitcher;
