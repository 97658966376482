import React, { useCallback, useContext, useState } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { Question, Answer, Result } from '../../types/data-types';
import useSelectAnswer from '../../hooks/useSelectAnswer';
import TemplateWrapper from '../questionTemplates/TemplateWrapper';
import {
  BackButton,
  NextButton,
  ControlButtonWrapper,
} from './style';
import QuestionSwitcher from '../questionTemplates/QuestionSwitcher';

interface QuestionProps {
  question: Question;
  submitAnswer: (answer: Answer) => void;
  submitManyAnswers: (answers: Answer[]) => void;
  cancelAnswer: () => void;
  currentQuestionResultHistory?: Result;
}

/*
This component has all the logic of the current question
it monitor the current state of checkmark Answer and decide if the selection order(add checkmark answer or remove checkmark answer)
sent by the children Template is valid or not

It also say if an answer can be submitted, how it should be submitted, and give the order accordingly
It also give the order of canceling a question

*/
const AskQuestion = ({
  question,
  submitAnswer,
  submitManyAnswers,
  cancelAnswer,
  currentQuestionResultHistory,
}: QuestionProps): JSX.Element => {
  const [language] = useContext(LanguageContext);
  const [error, setError] = useState(false);
  const {
    selectedAnswers,
    validAnswerSelection,
    addSelectedAnswer,
    removeSelectedAnswer,
    resetSelectedAnswers,
  } = useSelectAnswer(question, currentQuestionResultHistory);

  const submitSelectedAnswer = useCallback(() => {
    if (validAnswerSelection) {
      setError(false);
      if (selectedAnswers.length === 1) {
        submitAnswer(selectedAnswers[0]);
      } else {
        submitManyAnswers(selectedAnswers);
      }
      resetSelectedAnswers();
    } else {
      setError(true);
    }
  }, [
    selectedAnswers,
    submitAnswer,
    submitManyAnswers,
    validAnswerSelection,
    resetSelectedAnswers,
  ]);
  return (
    <>
      <TemplateWrapper question={question} error={error}>
        <QuestionSwitcher
          question={question}
          selectedAnswers={selectedAnswers}
          addSelectedAnswer={addSelectedAnswer}
          removeSelectedAnswer={removeSelectedAnswer}
        />
      </TemplateWrapper>
      <ControlButtonWrapper>
        <BackButton
          type="button"
          onClick={cancelAnswer}
          disabled={question.index <= 1}
        >
          {language === 'en' ? 'Back' : 'Retour'}
        </BackButton>
        <NextButton
          type="button"
          onClick={submitSelectedAnswer}
          className={!validAnswerSelection ? 'disabled' : ''}
        >
          {language === 'en' ? 'Next' : 'Suivant'}
        </NextButton>
      </ControlButtonWrapper>
    </>
  );
};

export default AskQuestion;
