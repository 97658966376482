import styled from 'styled-components';

export const ResetButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: flex-end;
  background: transparent;

  img {
    width: 250px;
  }

  @media only screen and (max-width: 992px) {
    padding-bottom: 20px;
  }
`;

export const ResultsContainer = styled.div`
  text-align: -webkit-center;
`;

export const DoubleResultsContainer = styled.div`
  align-items: flex-end;
  button {
    height: min-content;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;

    div {
      order: 1;
    }

    button {
      order: 2;
    }
  }
`;
