import React from 'react';
import BasicQuestion from '../BasicQuestion';
import ColorPalette from '../ColorPalette';
import ImageCarousel from '../ImageCarousel';
import QuestionTemplateProps from '../questionTemplateProps';
import PhotoGrid from '../PhotoGrid';
import PhotoBubbles from '../PhotoBubbles';

const QuestionSwitcher = (props: QuestionTemplateProps): JSX.Element => {
  const {
    question: { index },
  } = props;

  switch (index) {
    case 1:
    case 3:
    case 5:
    case 8:
    case 9:
      return <PhotoBubbles {...props} />;

    case 2:
    case 7:
      return <PhotoGrid {...props} />;

    case 4:
    case 6:
      return <ImageCarousel {...props} />;

    case 10:
      return <ColorPalette {...props} />;

    default:
      return <BasicQuestion {...props} />;
  }
};

export default QuestionSwitcher;
