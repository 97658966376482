import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import AskQuestion from './components/AskQuestion';
import LandingPage from './components/LandingPage';
import ProgressBar from './components/ProgressBar';
import TestResult from './components/TestResult';
import useTestReducer from './hooks/useTestReducer';
import { Persona, Score } from './types/data-types';
import JsonManager from './utils/JsonManager';
import { retrieveStateFromLocalStorage } from './utils/manageStateInLocalStorage';

function getMainPersonaFromTotalScore(totalScore: Score[]): Persona[] {
  return totalScore
    .filter((result, i, results) => result.points === results[0].points)
    .map(selectedResult => JsonManager.getPersona(selectedResult.personaId))
    .filter((persona): persona is Persona => !!persona);
}

const retrievedFromLocalStorage = retrieveStateFromLocalStorage();

/*

This component manage all the logic of the quizz, it knows constently the state of the quizz and is responsible to pass it down to childComponent
This component say what is the current question and also if the quizz is over or not

*/
const App = (): JSX.Element => {
  const [
    { getCurrentQuestion: getCurrentQuestionIndex, getTotalScore, getCurrentQuestionResultHistory },
    { resetState, submitAnswer, submitManyAnswers, cancelAnswer },
  ] = useTestReducer(retrievedFromLocalStorage);

  const [showQuiz, setShowQuiz] = useState(false);
  const [personaResults, setPersonaResult] = useState<Persona[]>();
  const [currentQuestion, setCurrentQuestion] = useState(
    JsonManager.getQuestion(getCurrentQuestionIndex())
  );
  const [currentQuestionResultHistory, setCurrentQuestionResultHistory] = useState(
    getCurrentQuestionResultHistory
  );
  const numberOfQuestions = JsonManager.getNumberOfQuestions();

  useEffect(() => {
    if (currentQuestion && currentQuestion?.index > 1) {
      setShowQuiz(true);
    }
    if (getCurrentQuestionIndex() > numberOfQuestions) {
      setPersonaResult(getMainPersonaFromTotalScore(getTotalScore()));
      setCurrentQuestion(undefined);
    } else {
      setCurrentQuestion(JsonManager.getQuestion(getCurrentQuestionIndex()));
      setCurrentQuestionResultHistory(getCurrentQuestionResultHistory());
    }
  }, [
    currentQuestion,
    getCurrentQuestionIndex,
    getTotalScore,
    numberOfQuestions,
    getCurrentQuestionResultHistory,
  ]);

  const resetQuiz = useCallback(() => {
    setShowQuiz(false);
    setPersonaResult(undefined);
    resetState();
  }, [resetState]);

  return (
    <div className="App">
      {!showQuiz && <LandingPage clicked={() => setShowQuiz(true)} />}

      {currentQuestion && showQuiz && (
        <>
          <ProgressBar
            selectedIndex={currentQuestion.index}
            numberOfQuestions={numberOfQuestions}
          />
          <AskQuestion
            question={currentQuestion}
            submitAnswer={submitAnswer}
            submitManyAnswers={submitManyAnswers}
            cancelAnswer={cancelAnswer}
            currentQuestionResultHistory={currentQuestionResultHistory}
          />
        </>
      )}
      {personaResults && <TestResult personaResults={personaResults} resetQuiz={resetQuiz} />}
    </div>
  );
};

export default App;
