import React, { useCallback, useContext } from 'react';
import { LanguageContext } from '../../../LanguageContext';
import { Answer } from '../../../types/data-types';
import LocalizeImageLanguage from '../../../utils/LocalizeImageLanguage';
import QuestionTemplateProps from '../questionTemplateProps';
import { PhotoGridItem, PhotoBubble, BubbleGridWrapper, OptionButton } from './style';

const PhotoBubbles = ({
  selectedAnswers,
  addSelectedAnswer,
  removeSelectedAnswer,
  question,
}: QuestionTemplateProps): JSX.Element => {
  const [language] = useContext(LanguageContext);
  const isSelected = useCallback(
    (answerId: string) => !!selectedAnswers.find(sa => sa.answerId === answerId),
    [selectedAnswers]
  );
  const onSelect = useCallback(
    (answer: Answer) =>
      isSelected(answer.answerId) ? removeSelectedAnswer(answer) : addSelectedAnswer(answer),
    [isSelected, addSelectedAnswer, removeSelectedAnswer]
  );

  return (
    <BubbleGridWrapper className="row">
      {question?.answers.map((answer: Answer, index) => {
        const { answerId, fgImg, bgColor } = answer;

        const photoBubbleImg = LocalizeImageLanguage(fgImg, language);

        return (
          <PhotoGridItem
            key={answerId}
            className={`col-4 ${index !== 3 && index !== 4 && index !== 5 ? 'pr' : 'pl'}
            ${index === 1 || index === 4 || index === 7 ? 'sm-top' : ''}
            ${isSelected(answerId) ? 'selected' : ''}`}
          >
            <OptionButton onClick={(): void => onSelect(answer)}>
              <PhotoBubble
                src={photoBubbleImg}
                bgColor={bgColor}
                className="photo"
                alt={answerId}
              />
            </OptionButton>
          </PhotoGridItem>
        );
      })}
    </BubbleGridWrapper>
  );
};

export default PhotoBubbles;
