import JSON from '../data/tastetest_rules.json';
import { RulesJSON, Question, Persona } from '../types/data-types';

const importedJson = JSON as RulesJSON;

class JsonManager {
  rawData: RulesJSON;

  constructor(json: RulesJSON) {
    this.rawData = json;
  }

  getQuestion(index: number): Question | undefined {
    return (
      this.rawData &&
      this.rawData.questions.find((question) => question.index === index)
    );
  }

  getNumberOfQuestions(): number {
    return this.rawData && this.rawData.questions.length;
  }

  getPersona(personaId: string): Persona | undefined {
    return (
      this.rawData &&
      this.rawData.persona.find((persona) => persona.id === personaId)
    );
  }
}
const singleton = new JsonManager(importedJson);
export default singleton;
