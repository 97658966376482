import styled from 'styled-components';
import { boxShadowFocus, checkmark } from '../../styles/ui';

interface GridItemProps {
  bgImg?: string;
}

interface GridPhotoProps {
  fgImg?: string;
}

export const GridWrapper = styled.div`
  height: 53vh;

  @media only screen and (min-width: 376px) {
    height: 59vh;
  }

  @media only screen and (min-width: 600px) {
    height: 72vh;
  }
`;

export const GridCol = styled.div<GridItemProps>`
  background: #ebebeb url(${(p): string | undefined => p.bgImg}) center center /
    cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33%;
  cursor: pointer;

  &.selected {
    &:before {
      ${checkmark}
    }
    .photo {
      border: 1px solid #000;
    }
  }
`;

export const GridButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  height: 75%;
  width: 75%;

  &:focus {
    ${boxShadowFocus}
  }
`;

export const GridPhoto = styled.img<GridPhotoProps>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;

  &:hover {
    border: 1px solid #000;
  }
`;
