import React, { useCallback, useContext } from 'react';
import { LanguageContext } from '../../../LanguageContext';
import { Answer } from '../../../types/data-types';
import LocalizeImageLanguage from '../../../utils/LocalizeImageLanguage';
import QuestionTemplateProps from '../questionTemplateProps';
import { PaletteWrapper, PaletteButton, PalettePhoto, SelectedBox, PaletteSwatch } from './style';

const ColorPalette = ({
  selectedAnswers,
  addSelectedAnswer,
  removeSelectedAnswer,
  question,
}: QuestionTemplateProps): JSX.Element => {
  const [language] = useContext(LanguageContext);
  const isSelected = useCallback(
    (answerId: string) => !!selectedAnswers.find(sa => sa.answerId === answerId),
    [selectedAnswers]
  );
  const onSelect = useCallback(
    (answer: Answer) =>
      isSelected(answer.answerId) ? removeSelectedAnswer(answer) : addSelectedAnswer(answer),
    [isSelected, addSelectedAnswer, removeSelectedAnswer]
  );
  return (
    <PaletteWrapper>
      {question?.answers.map((answer: Answer) => {
        const { answerId, bgColors, fgImg } = answer;

        const PalleteImage = LocalizeImageLanguage(fgImg, language);

        return (
          <PaletteButton key={answerId} onClick={(): void => onSelect(answer)}>
            <SelectedBox className={isSelected(answerId) ? 'selected' : ''}>
              <PalettePhoto src={PalleteImage} className="photo" alt={answerId} />
            </SelectedBox>
            <div className="row mx-0">
              {bgColors?.map(color => {
                return <PaletteSwatch key={color} bgColor={color} />;
              })}
            </div>
          </PaletteButton>
        );
      })}
    </PaletteWrapper>
  );
};

export default ColorPalette;
