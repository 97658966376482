import { useState, useCallback, useEffect } from 'react';
import { Answer, Question, Result } from '../types/data-types';

interface UseSelectAnswer {
  selectedAnswers: Answer[];
  validAnswerSelection: boolean;
  addSelectedAnswer: (answer: Answer) => void;
  removeSelectedAnswer: (answer: Answer) => void;
  resetSelectedAnswers: () => void;
}

function getPreSelectedAnswers(
  answers: Answer[],
  resultHistory?: Result
): Answer[] {
  if (!resultHistory) {
    return [];
  }
  return answers.filter((answer) =>
    resultHistory.answerIds.includes(answer.answerId)
  );
}
function useSelectAnswer(
  question: Question,
  currentQuestionResultHistory?: Result
): UseSelectAnswer {
  const { numberOfPossibleAnswers, answers } = question;
  const [selectedAnswers, setSelectedAnswers] = useState<Answer[]>([]);

  useEffect(
    () =>
      setSelectedAnswers(
        getPreSelectedAnswers(answers, currentQuestionResultHistory)
      ),
    [answers, currentQuestionResultHistory]
  );

  const validAnswerSelection =
    selectedAnswers.length === numberOfPossibleAnswers;
  const resetSelectedAnswers = useCallback(() => setSelectedAnswers([]), []);
  const addSelectedAnswer = useCallback(
    (answer: Answer) => {
      if (selectedAnswers.length < numberOfPossibleAnswers) {
        setSelectedAnswers((sa) => [...sa, answer]);
      } else {
        // if only one answer is allowed, let the user change their answer without deselecting
        setSelectedAnswers((sa) => (sa.length > 1 ? [...sa] : [answer]));
      }
    },
    [selectedAnswers.length, numberOfPossibleAnswers]
  );
  const removeSelectedAnswer = useCallback(({ answerId }: Answer) => {
    setSelectedAnswers((sa) =>
      sa.filter((answer) => answer.answerId !== answerId)
    );
  }, []);

  return {
    selectedAnswers,
    validAnswerSelection,
    addSelectedAnswer,
    removeSelectedAnswer,
    resetSelectedAnswers,
  };
}

export default useSelectAnswer;
