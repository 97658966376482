import styled, { css } from 'styled-components';

// font families
export const ffRegular = css`
  font-family: 'Poppins', sans-serif;
`;

// font sizes
export const fsLarge = css`
  font-size: 24px;
`;

export const fsMedium = css`
  font-size: 18px;
`;

export const fsRegular = css`
  font-size: 16px;
`;

export const fsSmall = css`
  font-size: 14px;
`;

export const fsExtraSmall = css`
  font-size: 12px;
`;

export const fwNormal = css`
  font-weight: 400;
`;

export const fwMedium = css`
  font-weight: 600;
`;

export const fwBold = css`
  font-weight: 700;
`;

// components
export const Title = styled.h1`
  ${ffRegular}
  ${fsRegular}
  ${fwNormal}
  line-height: 1.25;
  margin-block-end: 0.25em;

  @media only screen and (min-width: 768px) {
    ${fsLarge}
  }
`;

export const Subtitle = styled.p`
  ${ffRegular}
  ${fsMedium}
  ${fwNormal}
  line-height: 1.5;
  text-transform: uppercase;
  margin: 15px 0;
`;

export const Intro = styled.p`
  ${ffRegular}
  ${fsMedium}
  ${fwNormal}
  line-height: 1.5;
`;

export const Description = styled.p`
  ${ffRegular}
  ${fsSmall}
  ${fwNormal}
  line-height: 1.5;
  margin: 15px 0;
`;

export const Message = styled.p`
  ${fsExtraSmall}
  ${ffRegular}
  ${fwNormal}
  color: red;
  text-transform: uppercase;
  margin-block-start: 0.25em;

  @media only screen and (min-width: 768px) {
    ${fsSmall}
  }
`;

export const ErrorMessage = styled(Message)`
  ${fwMedium}
  font-style: italic;
`;

export const BoldMessage = styled.span`
  ${fwBold}
`;

export const EmphasisDescription = styled(Description)`
  font-style: italic;
  color: #2fbd9c;
  ${fsRegular}
  margin-bottom: 2em;
`;
