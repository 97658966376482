import styled from 'styled-components';

export const QuestionWrapper = styled.div`
  text-align: left;
  padding: 0 1em;

  @media only screen and (min-width: 768px) {
    padding: 1em 3em;
  }
`;
