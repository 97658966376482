import Slider from 'react-slick';
import styled from 'styled-components';
import { Message } from '../../styles/typography';
import { boxShadowFocus, checkmark } from '../../styles/ui';
import { GridWrapper } from '../PhotoGrid/style';

interface ItemProps {
  img?: string;
}

export const ImageGridWrapper = styled(GridWrapper)`
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0 3em;
  }
`;

export const ItemButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
`;

export const Item = styled.div<ItemProps>`
  margin: 2px;
  background-image: ${(props): string => `url(${props.img})`};
  background-color: #ebebeb;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 100px;
  height: 38vh;

  @media only screen and (min-width: 376px) {
    padding: 150px;
    height: 48vh;
  }

  @media only screen and (min-width: 768px) {
    padding: 100px;
    height: 50vh;
  }

  @media only screen and (min-width: 1024px) {
    padding: 150px;
  }

  &:hover {
    border: 1px solid #000;
  }

  &:focus {
    ${boxShadowFocus}
  }

  &.selected {
    &:before {
      ${checkmark}
    }
    border: 1px solid #000;
  }
`;

export const Thumbnail = styled.div<ItemProps>`
  background-image: ${(props): string => `url(${props.img})`};
  background-color: #ebebeb;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  height: 50px;

  @media only screen and (min-width: 768px) {
    height: 70px;
  }

  &:active {
    cursor: grabbing;
  }

  &.selected {
    &:before {
      ${checkmark}
    }
    border: 1px solid #000;
  }
`;

export const Instructions = styled(Message)`
  color: #000;
  text-align: center;
`;

export const ThumbnailSlider = styled(Slider)`
  margin-top: 10px;

  .slick-list {
    max-width: 78%;
    margin: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }
  .slick-next {
    right: 12px;
  }
  .slick-prev {
    left: 12px;
  }

  @media only screen and (min-width: 376px) {
    .slick-next {
      right: 20px;
    }
    .slick-prev {
      left: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    .slick-list {
      max-width: 65%;
      margin: auto;
    }
    .slick-next {
      right: 50px;
    }
    .slick-prev {
      left: 50px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .slick-next {
      right: 150px;
    }
    .slick-prev {
      left: 150px;
    }
  }
`;
