import { css } from 'styled-components';
import { fsSmall } from './typography';

// a11y
export const focus = css`
  outline: 2px solid #3685a3 !important;
  transition: outline 0.1s linear !important;

  &::-moz-focus-inner {
    border: 0;
  }
`;

export const boxShadowFocus = css`
  box-shadow: 0 0 0 2px #3685a3 !important;
  transition: box-shadow 0.1s linear !important;
  outline: 0 !important;

  &::-moz-focus-inner {
    border: 0;
  }
`;

// button
export const button = css`
  background-color: #000;
  color: #fff;
  border-radius: 0;
  border: none;
  font-size: ${fsSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 10px;

  @media only screen and (min-width: 375px) {
    padding: 10px 20px;
  }

  &:focus {
    ${boxShadowFocus}
  }
`;

// misc.
export const boxShadow = css`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.75);
`;

export const checkmark = css`
  content: '';
  position: absolute;
  display: block;
  background: url('/assets/checkbox-white.svg') no-repeat;
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 10px;
  z-index: 10;
  pointer-events: none;
  ${boxShadow}
`;
