import React from 'react';
import {LanguageContext} from '../LanguageContext';

interface languageCopy {
  en: any;
  fr: any;
}

export const useLanguageCopy = (languageCopy: languageCopy) => {
  const [language] = React.useContext(LanguageContext);
  return languageCopy[language];
};
