import { LanguageString } from '../types/data-types';
import { AvailableLanguages } from './languageManager';

const LocalizeImageLanguage = (
  image: string | LanguageString | undefined,
  language: AvailableLanguages
) => {
  const localizedImage = typeof image === 'string' ? image : (image as LanguageString)[language];

  return localizedImage;
};

export default LocalizeImageLanguage;
