import styled from 'styled-components';
import { button } from '../styles/ui';

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;

  @media only screen and (min-width: 375px) {
    right: 8px;
    top: 8px;
  }

  @media only screen and (min-width: 768px) {
    right: 20px;
    top: 20px;
  }
`;

export const Button = styled.button`
  ${button}
`;
