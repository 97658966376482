import styled from 'styled-components';

export const QuestionWrapper = styled.div`
  padding-bottom: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 65px;
  max-height: calc(100vh - 65px);
  overflow-y: scroll;
`;

export const IntroWrapper = styled.div`
  text-align: left;
  padding: 0 1em 1.5em 1em;

  @media only screen and (min-width: 768px) {
    padding: 0 3em 1em 3em;
  }
`;
