import React, { useContext } from 'react';
import { Title } from '../styles/typography';
import {
  PageWrapper,
  StartButton,
  Logo,
  Intro,
  StyledEmphasisDescription,
  MediumText,
} from './style';
import startButton from '../../assets/start-button.jpg';
import startButtonFr from '../../assets/start-buttonFr.jpg';
import logo from '../../assets/logo.jpg';
import logoFr from '../../assets/logoFr.jpg';
import LanguageSwitcher from '../LanguageSwitcher';
import { useLanguageCopy } from '../../utils/useLanguageCopy';
import { LanguageContext } from '../../LanguageContext';

const languageText = {
  en: {
    titlePreMood: 'Answer 10 questions to find the ',
    titleMood: 'mood ',
    titlePostMood:
      'that will express best your desires. Please be spontaneous, don’t overthink this.',
    subTitle: 'When there is more than one choice required, always put the one you ',
    subTitleStyled: 'like best first.',
  },
  fr: {
    titlePreMood: 'Répondez aux 10 questions pour découvrir ',
    titleMood: 'l’ambiance ',
    titlePostMood:
      'qui exprime le mieux vos goûts. Soyez spontanés, suivez votre premier instinct.',
    subTitle: 'Lorsque plus d’un choix est requis, toujours indiquer celui que vous ',
    subTitleStyled: 'aimez le plus en premier.',
  },
};

interface LandingPageProps {
  clicked: () => void;
}

const LandingPage = ({ clicked }: LandingPageProps): JSX.Element => {
  const languageCopy = useLanguageCopy(languageText);
  const [language] = useContext(LanguageContext);
  return (
    <PageWrapper>
      <LanguageSwitcher />
      <div className="container">
        <Logo src={language === 'en' ? logo : logoFr} alt="Tastest by Venue and Menu" />
        <Intro>
          <Title>
            {languageCopy.titlePreMood}
            <MediumText>{languageCopy.titleMood}</MediumText>
            {languageCopy.titlePostMood}
          </Title>
          <StyledEmphasisDescription>
            {languageCopy.subTitle}
            <MediumText>{languageCopy.subTitleStyled}</MediumText>
          </StyledEmphasisDescription>
        </Intro>
        <StartButton type="button" onClick={clicked}>
          <img src={language === 'en' ? startButton : startButtonFr} alt="Start now" />
        </StartButton>
      </div>
    </PageWrapper>
  );
};

export default LandingPage;
