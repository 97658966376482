import styled from 'styled-components';
import { boxShadowFocus, checkmark } from '../../styles/ui';

interface PaletteSwatchProps {
  bgColor: string;
}

export const PaletteWrapper = styled.div`
  padding: 0 2px;
`;

export const PaletteButton = styled.button`
  position: relative;
  width: 100%;

  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &:focus {
    ${boxShadowFocus}
  }
`;

export const SelectedBox = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  top: 0.75vh;

  @media only screen and (min-width: 376px) {
    height: 4.75vh;
    top: 0.75vh;
  }

  @media only screen and (min-width: 768px) {
    height: 6.25vh;
  }

  &:hover {
    border: 1px solid #000;
  }

  &.selected {
    border: 1px solid #000;

    &:before {
      ${checkmark}
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      top: 0.125vh;

      @media only screen and (min-width: 376px) {
        top: 0.75vh;
      }

      @media only screen and (min-width: 768px) {
        top: 1.5vh;
      }
    }
  }
`;

export const PaletteSwatch = styled.div<PaletteSwatchProps>`
  background-color: ${(p): string => p.bgColor};
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  height: 5.35vh;

  @media only screen and (min-width: 376px) {
    height: 6.125vh;
  }

  @media only screen and (min-width: 768px) {
    height: 7.65vh;
  }
`;

export const PalettePhoto = styled.img`
  width: auto;
  height: 5.75vh;

  @media only screen and (min-width: 376px) {
    height: 6.25vh;
  }

  @media only screen and (min-width: 768px) {
    height: 7.75vh;
  }
`;
