import styled, { css } from 'styled-components';
import { fsSmall, fwMedium } from '../styles/typography';
import { boxShadowFocus } from '../styles/ui';

const controlButton = css`
  background-color: #000;
  border: 4px solid #fff;
  border-bottom: 2px solid #fff;
  color: #fff;
  ${fsSmall}
  ${fwMedium}
  text-transform: uppercase;
  width: 50%;
  cursor: pointer;

  &:disabled {
    background-color: #343434;
    cursor: not-allowed;
  }

  &:focus {
    ${boxShadowFocus}
  }

  @media only screen and (min-width: 768px) {
    border: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
`;

export const BackButton = styled.button`
  ${controlButton}
  padding: 10px 0;
  border-right: 2px solid #fff;

  @media only screen and (min-width: 768px) {
    padding: 10px 0 10px 20%;
    border-right: 1px solid #fff;
  }

  @media only screen and (min-width: 1024px) {
    padding: 10px 0 10px 35%;
  }
`;

export const NextButton = styled.button`
  ${controlButton}
  padding: 10px 0;
  border-left: 2px solid #fff;

  &.disabled {
    opacity: 0.75;
  }

  @media only screen and (min-width: 768px) {
    padding: 10px 20% 10px 0;
    border-left: 1px solid #fff;
  }

  @media only screen and (min-width: 1024px) {
    padding: 10px 35% 10px 0;
  }
`;

export const ControlButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;
