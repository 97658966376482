import React, { useContext } from 'react';
import { LanguageContext } from '../../../LanguageContext';
import { Question } from '../../../types/data-types';
import { Message, Title, ErrorMessage, BoldMessage } from '../../styles/typography';
import { QuestionWrapper, IntroWrapper } from './style';

interface WrapperProps {
  question: Question;
  error: boolean;
  children: JSX.Element;
}

const TemplateWrapper = ({ question, error, children }: WrapperProps): JSX.Element => {
  const [language] = useContext(LanguageContext);

  return (
    <QuestionWrapper>
      <IntroWrapper>
        <Title>{question?.friendlyName[language]}</Title>
        {!error ? (
          <Message>
            {language === 'en' ? 'Select' : 'Sélectionner'} {question?.numberOfPossibleAnswers}{' '}
            {language === 'en' ? '' : 'choix'}
          </Message>
        ) : (
          <>
            <ErrorMessage>
              {language === 'en' ? 'Please' : 'Veuillez'}{' '}
              <BoldMessage>
                {language === 'en' ? 'select' : 'sélectionner'} {question?.numberOfPossibleAnswers}{' '}
                {language === 'en' ? '' : 'choix'}
              </BoldMessage>{' '}
              {language === 'en' ? 'to continue' : 'pour continuer'}
            </ErrorMessage>
          </>
        )}
      </IntroWrapper>
      {children}
    </QuestionWrapper>
  );
};

export default TemplateWrapper;
