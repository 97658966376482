import { useReducer, useCallback, useEffect } from 'react';
import { Result, Answer, Score } from '../types/data-types';
import { storeStateToLocalStorage } from '../utils/manageStateInLocalStorage';
import { testReducer, State, nextQuestion, prevQuestion, setState } from './testReducer';

// Helpers

function mergeManyScores(scoresArray: Score[][]): Score[] {
  return scoresArray.reduce((totalScore, scores) => {
    const updatedTotalScore = [...totalScore];
    scores.forEach(score => {
      const foundIndex = totalScore.findIndex(ts => ts.personaId === score.personaId);
      if (foundIndex === -1) {
        updatedTotalScore.push({ ...score });
      } else {
        updatedTotalScore[foundIndex].points += score.points;
      }
    });
    return updatedTotalScore;
  }, []);
}
function mergeAnswersIntoResult(answers: Answer[]): Result {
  const scores = mergeManyScores(answers.map(answer => answer.scores));
  const answerIds = answers.reduce<string[]>((acc, { answerId }) => acc.concat(answerId), []);
  return {
    answerIds,
    scores,
  };
}
// Main Hook

const initialState: State = {
  currentQuestion: 1,
  resultsHistory: [],
};

export interface Selectors {
  state: State;
  getCurrentQuestion: () => number;
  getCurrentQuestionResultHistory: () => Result | undefined;
  getTotalScore: () => Score[];
}

export interface Callbacks {
  resetState: () => void;
  submitAnswer: (answer: Answer) => void;
  submitManyAnswers: (answers: Answer[]) => void;
  cancelAnswer: () => void;
}

function useTestReducer(passedInitialState = initialState): [Selectors, Callbacks] {
  const [state, dispatch] = useReducer(testReducer, passedInitialState);

  const getCurrentQuestion = useCallback(() => state.currentQuestion, [state.currentQuestion]);
  const getCurrentQuestionResultHistory = useCallback(() => {
    const { resultsHistory, currentQuestion } = state;
    return resultsHistory[currentQuestion - 1];
  }, [state]);
  const getTotalScore = useCallback(() => {
    return mergeManyScores(state.resultsHistory.map(result => result.scores)).sort((a, b) => {
      if (a.points > b.points) {
        return -1;
      }
      if (a.points < b.points) {
        return 1;
      }
      return 0;
    });
  }, [state.resultsHistory]);

  const resetState = useCallback(() => {
    dispatch(setState(initialState));
  }, [dispatch]);

  const submitAnswer = useCallback(
    (answer: Answer) => dispatch(nextQuestion(mergeAnswersIntoResult([answer]))),
    [dispatch]
  );

  const submitManyAnswers = useCallback(
    (answers: Answer[]) => dispatch(nextQuestion(mergeAnswersIntoResult(answers))),
    [dispatch]
  );

  const cancelAnswer = useCallback(() => dispatch(prevQuestion()), [dispatch]);

  useEffect(() => storeStateToLocalStorage(state), [state]);

  return [
    {
      getCurrentQuestion,
      state,
      getTotalScore,
      getCurrentQuestionResultHistory,
    },
    {
      resetState,
      submitAnswer,
      submitManyAnswers,
      cancelAnswer,
    },
  ];
}

export default useTestReducer;
