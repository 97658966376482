export enum AvailableLanguages {
  EN = 'en',
  FR = 'fr',
}

function isLanguageValid(lang: string): boolean {
  return lang === AvailableLanguages.EN || lang === AvailableLanguages.FR;
}

function getLanguageFromUrl(): string {
  return window.location.pathname.substr(1, 2);
}

function getBrowserLanguage(): string {
  return navigator.language.substr(0, 2);
}

function getDefaultLanguage(): AvailableLanguages {
  const languageFromUrl = getLanguageFromUrl();
  if (isLanguageValid(languageFromUrl)) {
    return languageFromUrl as AvailableLanguages;
  }
  const languageFromBrowser = getBrowserLanguage();
  if (isLanguageValid(languageFromBrowser)) {
    return languageFromBrowser as AvailableLanguages;
  }

  return AvailableLanguages.EN;
}

export default getDefaultLanguage;
