import styled from 'styled-components';
import { ffRegular, fsExtraSmall } from '../styles/typography';
import { button } from '../styles/ui';

export const PersonaWrapper = styled.div`
  text-align: center;
  padding: 1em;
`;

export const PersonaIcon = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #ebebeb;
`;

export const TagList = styled.ul`
  box-sizing: inherit;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25em;
`;

export const Tag = styled.li`
  ${ffRegular}
  ${fsExtraSmall}
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0.5em;
`;

export const PersonaCollage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
  border-radius: 50%;
  background-color: #ebebeb;

  @media only screen and (min-width: 376px) {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }

  @media only screen and (min-width: 768px) {
    width: 275px;
    height: 275px;
  }
`;

export const ViewButton = styled.a`
  ${button}
`;
