import React, { PropsWithChildren, useState } from 'react';
import getDefaultLanguage, {
  AvailableLanguages,
} from './utils/languageManager';

type DummyFunction = () => void;
type ContextValue = [
  AvailableLanguages,
  React.Dispatch<React.SetStateAction<AvailableLanguages>> | DummyFunction
];
const defaultValue = getDefaultLanguage();
export const LanguageContext = React.createContext<ContextValue>([
  defaultValue,
  // eslint-disable-next-line
  () => {},
]);

export const LanguageProvider = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const [language, setLanguage] = useState(defaultValue);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};
