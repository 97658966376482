import React from 'react';
import logo from '../../assets/logo-sm.png';
import { Bar, ProgressBarProps, SmallLogo, SmallPrint, Wrapper } from './style';
import LanguageSwitcher from '../LanguageSwitcher';

const ProgressBar = ({
  selectedIndex,
  numberOfQuestions,
}: ProgressBarProps): JSX.Element => (
  <Wrapper className="row">
    <div className="col-lg-10 col-md-9 col-sm-8 col-6 pl-0">
      <Bar
        selectedIndex={selectedIndex}
        numberOfQuestions={numberOfQuestions}
      />
    </div>
    <div className="col-lg-2 col-md-3 col-sm-4 col-6 pr-0">
      <div className="d-flex align-items-center ml-xl-5 ml-lg-4 ml-1">
        <SmallLogo src={logo} alt="Tastest" />
        <SmallPrint>
          {selectedIndex}/{numberOfQuestions}
        </SmallPrint>
      </div>
    </div>
    <LanguageSwitcher />
  </Wrapper>
);

export default ProgressBar;
