import { State } from '../hooks/testReducer';

export const LOCAL_STORAGE_ITEM_KEY = 'TASTE_TEST_STATE';

export function storeStateToLocalStorage(state: State): void {
  const stringifyed = JSON.stringify(state);
  window.localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, stringifyed);
}

export function retrieveStateFromLocalStorage(): State | undefined {
  const retrieved = window.localStorage.getItem(LOCAL_STORAGE_ITEM_KEY);
  if (retrieved) {
    return JSON.parse(retrieved) as State;
  }
  return undefined;
}

export function clearStateFromLocalStorage(): void {
  window.localStorage.clear();
}
