import styled from 'styled-components';
import { fsSmall } from '../styles/typography';

export interface ProgressBarProps {
  selectedIndex: number;
  numberOfQuestions: number;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 1em 1em 0;

  @media only screen and (min-width: 768px) {
    margin: 1.5em 4.5em 1.5em 0;
  }
`;

export const Bar = styled.div<ProgressBarProps>`
  width: calc(${(p): number => (p.selectedIndex / p.numberOfQuestions) * 100}%);
  background-color: #000;
  height: 10px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;

  &:after {
    content: '•';
    color: #fff;
    position: absolute;
    font-size: 19px;
    right: 1px;
    top: -8px;
  }
`;

export const SmallPrint = styled.p`
  ${fsSmall};
  margin: 0 15px;

  @media only screen and (min-width: 1025px) {
    margin: 0 25px;
  }
`;

export const SmallLogo = styled.img`
  width: 50px;
  height: auto;

  @media only screen and (min-width: 768px) {
    width: 60px;
  }
`;
